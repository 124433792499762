import { SHOPID, SHOPSTAFFID } from '@/constants'
import { service } from '@/utils/request'
import { getSession } from '@/utils/session'

export default {
  saveShop (payload) {
    return service.post('/shop/save', payload)
  },
  getShopInfo (payload) {
    return service.get('/shop/getShop', {
      params: Object.assign({ shopStaffId: getSession(SHOPSTAFFID) }, payload)
    })
  },
  saveShopAuth (payload) {
    return service.post('/shopAuth/save', payload)
  },
  getShopAuthData (payload) {
    return service.get('/shopAuth/getShopAuth', {
      params: payload
    })
  },
  saveShopAddr (payload) {
    return service.post('/shopAddr/save', payload)
  },
  getAddrPage (payload) {
    return service.get('/shopAddr/page', {
      params: payload
    })
  },
  getAddrDetail (payload) {
    return service.get('/shopAddr/getShopAddr', {
      params: payload
    })
  },
  delShopAddr (payload) {
    return service.postForm('/shopAddr/delete', payload)
  },
  saveShopDesign (payload) {
    return service.post('/shopDesignStorage/save', payload)
  },
  releaseShopDesign (payload) {
    return service.post('/shopDesign/save', payload)
  },
  wxGetPreAuthUrl (payload) {
    return service.get('/shopWxma/getPreAuthUrl', {
      params: payload
    })
  },
  wxSendQueryAuth (payload) {
    return service.postForm('/shopWxma/getQueryAuth', payload)
  },
  wxGetAppletBaseData (payload) {
    return service.get('/shopWxma/getAccountInfo', {
      params: payload
    })
  },
  wxGetDomainResult (payload) {
    return service.get('/shopWxma/getDomainResult', {
      params: payload
    })
  },
  // 商品分组
  getProductGroupPage (payload) {
    return service.get('/productGroup/page', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  addProductGroup (payload) {
    return service.post('/productGroup/save', {
      shopId: getSession(SHOPID),
      ...payload
    })
  },
  deleteProductGroup (productGroupId) {
    return service.postForm('/productGroup/delete', { productGroupId, shopId: getSession(SHOPID) })
  },
  getProductByGroup (payload) {
    return service.get('/product/listProductByGroup', {
      params: Object.assign({ type: 0 }, payload)
    })
  },
  webDomain (payload) {
    return service.postForm('/shopWxma/updateWebviewdomain', payload)
  },
  apiDomain (payload) {
    return service.postForm('/shopWxma/modifyDomain', payload)
  },
  updateAdvert (payload) {
    return service.post('/shop/index/pop/saveOrUpdate', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  getAdvertDetail () {
    return service.get(`/shop/index/pop/detail/${getSession(SHOPID)}`)
  },
  updateGroup (payload) {
    return service.post('/shopMaterialGroup/save', payload)
  },
  getResourcePage (payload) {
    return service.get('/shopMaterial/page', {
      params: payload
    })
  },
  getSourceGroup (payload) {
    return service.get('/shopMaterialGroup/list', {
      params: Object.assign({ type: 0, shopId: getSession(SHOPID) }, payload)
    })
  },
  deleteImgResource (payload) {
    return service.postForm('/shopMaterial/delete', payload)
  },
  deleteGroup (payload) {
    return service.postForm('/shopMaterialGroup/delete', payload)
  },
  imgChangeGroup (payload) {
    return service.postForm('/shopMaterial/changeGroup', payload)
  },
  saveImgResource (payload) {
    return service.post('/shopMaterial/save', payload)
  },
  getVoucher (payload) {
    return service.get('/shopVoucher/pageByShop', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getVoucherTree () {
    return service.get('/shopVoucher/listTree')
  },
  getProductVoucherList (payload) {
    return service.get('/shopVoucher/list', {
      params: payload
    })
  },
  getVoucherImg (payload) {
    return service.get('/shopVoucher/listVoucherImg', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  submitVoucherImgs (payload) {
    return service.post('/shopVoucher/submitVoucher', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  getDesignList (payload) {
    return service.get('/shopDesignStorage/list', {
      params: payload
    })
  },
  deleteTemplate (payload) {
    return service.postForm('/shopDesignStorage/del', payload)
  },
  saveTemplate (payload) {
    return service.post('/shopDesignStorage/save', payload)
  },
  getDesignDetail (payload) {
    return service.get('/shopDesignStorage', {
      params: payload
    })
  },
  getTemplateList () {
    return service.get('/shopDesign/list', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
  getSaleClass () {
    return service.get('/saleClass/list', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
  getSaleClassContent (payload) {
    return service.get('/saleClassItem/listProduct', {
      params: payload
    })
  },
  saveSaleClassContent (payload) {
    return service.post('/saleClassItem/bind', payload)
  },
  saveSaleClass (payload) {
    return service.post('/saleClass/save', {
      shopId: getSession(SHOPID),
      ...payload
    })
  },
  getChooseProducts (payload) {
    return service.get('/saleClassItem/pageForCreate', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  removeProduct (payload) {
    return service.postForm('/saleClassItem/removeProduct', payload)
  },
  getSplitPage (payload) {
    return service.get('/shop/split/log/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getSplitAddData () {
    return service.get('/shop/split/log/getSplitAddData', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
  saveSplit (payload) {
    return service.post('/shop/split/log/saveSplit', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  commitCheckSplit (payload) {
    return service.postForm('/shop/split/log/commitCheck', payload)
  },
  deleteSplit (payload) {
    return service.postForm('/shop/split/log/delete', payload)
  },
  passSplit (payload) {
    return service.postForm('/shop/split/log/pass', payload)
  },
  refusedSplit (payload) {
    return service.postForm('/shop/split/log/refused', payload)
  },
  runSplit (payload) {
    return service.postForm('/shop/split/log/run', payload)
  },
  getSalePage (payload) {
    return service.get('/shop/sale/log/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getSaleAddData () {
    return service.get('/shop/sale/log/getSaleAddData', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
  saveSale (payload) {
    return service.post('/shop/sale/log/saveSale', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  commitCheckSale (payload) {
    return service.postForm('/shop/sale/log/commitCheck', payload)
  },
  deleteSale (payload) {
    return service.postForm('/shop/sale/log/delete', payload)
  },
  passSale (payload) {
    return service.postForm('/shop/sale/log/pass', payload)
  },
  refusedSale (payload) {
    return service.postForm('/shop/sale/log/refused', payload)
  },
  runSale (payload) {
    return service.postForm('/shop/sale/log/run', payload)
  },

  getSaleDetailPage (payload) {
    return service.get('/shop/sale/detail/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getSaleSelectUserPage (payload) {
    return service.get('/shop/sale/detail/pageSelectUser', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  saveSaleDetail (payload) {
    return service.post('/shop/sale/detail/saveSaleDetail', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  editSaleDetail (payload) {
    return service.post('/shop/sale/detail/editSaleDetail', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  deleteSaleDetail (payload) {
    return service.postForm('/shop/sale/detail/delete', payload)
  },
  getSummaryDataSale (payload) {
    return service.get('/shop/sale/detail/getSummaryData', {
      params: {
        ...payload,
      }
    })
  },
  getSaleBuyPage (payload) {
    return service.get('/shop/sale/buy/log/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getSaleBuyAddData () {
    return service.get('/shop/sale/buy/log/getSaleBuyAddData', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
  saveSaleBuy (payload) {
    return service.post('/shop/sale/buy/log/saveSaleBuy', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  commitCheckSaleBuy (payload) {
    return service.postForm('/shop/sale/buy/log/commitCheck', payload)
  },
  deleteSaleBuy (payload) {
    return service.postForm('/shop/sale/buy/log/delete', payload)
  },
  passSaleBuy (payload) {
    return service.postForm('/shop/sale/buy/log/pass', payload)
  },
  refusedSaleBuy (payload) {
    return service.postForm('/shop/sale/buy/log/refused', payload)
  },
  runSaleBuy (payload) {
    return service.postForm('/shop/sale/buy/log/run', payload)
  },

  getSaleBuyDetailPage (payload) {
    return service.get('/shop/sale/buy/detail/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getSaleBuySelectUserPage (payload) {
    return service.get('/shop/sale/buy/detail/pageSelectUser', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  saveSaleBuyDetail (payload) {
    return service.post('/shop/sale/buy/detail/saveSaleBuyDetail', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  editSaleBuyDetail (payload) {
    return service.post('/shop/sale/buy/detail/editSaleBuyDetail', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  deleteSaleBuyDetail (payload) {
    return service.postForm('/shop/sale/buy/detail/delete', payload)
  },
  getSummaryDataSaleBuy (payload) {
    return service.get('/shop/sale/buy/detail/getSummaryData', {
      params: {
        ...payload,
      }
    })
  },
  getArbitralPage (payload) {
    return service.get('/shop/arbitral/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  passArbitral (payload) {
    return service.postForm('/shop/arbitral/pass', payload)
  },
  refusedArbitral (payload) {
    return service.postForm('/shop/arbitral/refused', payload)
  },
  getEpBuyDetail (id) {
    return service.get('/shop/arbitral/getEpBuyDetail', {
      params: { id }
    })
  },
  getAsSalePage (payload) {
    return service.get('/user/account/as/sale/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  passAsSale (payload) {
    return service.postForm('/user/account/as/sale/pass', payload)
  },
  refusedAsSale (payload) {
    return service.postForm('/user/account/as/sale/refused', payload)
  },
  getShopNoticeList (payload) {
    return service.get('/shop/notice/list', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  saveShopNotice (payload) {
    return service.post('/shop/notice/save', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  deleteShopNotice (payload) {
    return service.postForm('/shop/notice/delete', payload)
  },
}
