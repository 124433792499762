import { SHOPID } from '@/constants'
import { service } from '@/utils/request'
import { getSession } from '@/utils/session'

export default {
  // 会员列表
  getMemberAsPage (params) {
    return service.get('/user/account/as/user/page', {
      params
    })
  },
  // 充值
  async chargeAsMoney (payload) {
    const { code, msg } = await service.post('/user/account/as/recharge', payload)
    if (code !== '00000') throw new Error(msg)
  },
  // 充值变动记录
  getChargeAsHistoryPage (params) {
    return service.get('/user/account/as/page', {
      params
    })
  },

  // 会员列表
  getMemberTpPage (params) {
    return service.get('/user/account/tp/user/page', {
      params
    })
  },
  // 充值
  async chargeTpMoney (payload) {
    const { code, msg } = await service.post('/user/account/tp/recharge', payload)
    if (code !== '00000') throw new Error(msg)
  },
  // 充值变动记录
  getChargeTpHistoryPage (params) {
    return service.get('/user/account/tp/page', {
      params
    })
  },

  // 会员列表
  getMemberZpPage (params) {
    return service.get('/user/account/zp/user/page', {
      params
    })
  },
  // 充值
  async chargeZpMoney (payload) {
    const { code, msg } = await service.post('/user/account/zp/recharge', payload)
    if (code !== '00000') throw new Error(msg)
  },
  // 充值变动记录
  getChargeZpHistoryPage (params) {
    return service.get('/user/account/zp/page', {
      params
    })
  },

  // 会员列表
  getMemberEpPage (params) {
    return service.get('/user/account/ep/user/page', {
      params
    })
  },
  // 充值
  async chargeEpMoney (payload) {
    const { code, msg } = await service.post('/user/account/ep/recharge', payload)
    if (code !== '00000') throw new Error(msg)
  },
  // 充值变动记录
  getChargeEpHistoryPage (params) {
    return service.get('/user/account/ep/page', {
      params
    })
  },

  // 会员列表
  getMemberRpPage (params) {
    return service.get('/user/account/rp/user/page', {
      params
    })
  },
  // 充值
  async chargeRpMoney (payload) {
    const { code, msg } = await service.post('/user/account/rp/recharge', payload)
    if (code !== '00000') throw new Error(msg)
  },
  // 充值变动记录
  getChargeRpHistoryPage (params) {
    return service.get('/user/account/rp/page', {
      params
    })
  },
  // 会员列表
  getMemberSpPage (params) {
    return service.get('/user/account/sp/user/page', {
      params
    })
  },
  // 充值
  async chargeSpMoney (payload) {
    const { code, msg } = await service.post('/user/account/sp/recharge', payload)
    if (code !== '00000') throw new Error(msg)
  },
  // 充值变动记录
  getChargeSpHistoryPage (params) {
    return service.get('/user/account/sp/page', {
      params
    })
  },
}
