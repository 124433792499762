import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/shop',
  component: AppLayout,
  children: [
    {
      path: 'info',
      component: () => import('@/views/shop/PageInfo.vue'),
      meta: { title: '店铺信息', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'authentication',
      component: () => import('@/views/shop/PageAuth.vue'),
      meta: { title: '店铺认证', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'voucher',
      component: () => import('@/views/shop/PageVoucher.vue'),
      meta: { title: '经营凭证', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'renovation',
      component: () => import('@/views/shop/PageAppletIndex.vue'),
      meta: { title: '店铺装修', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'address',
      component: () => import('@/views/shop/PageAddress.vue'),
      meta: { title: '店铺地址', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'logistics',
      component: () => import('@/views/shop/PageTemplate.vue'),
      meta: { title: '物流模板', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'logistics/add',
      component: () => import('@/views/shop/PageTemplateForm.vue'),
      meta: { title: '新建物流模板', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'logistics/edit/:id',
      component: () => import('@/views/shop/PageTemplateForm.vue'),
      meta: { title: '修改物流模板', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'split',
      component: () => import('@/views/shop/PageSplit.vue'),
      meta: { title: '拆分记录', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'sale',
      component: () => import('@/views/shop/PageSale.vue'),
      meta: { title: '指导销售', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'buy',
      component: () => import('@/views/shop/PageSaleBuy.vue'),
      meta: { title: '购买分配', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'arbitral',
      component: () => import('@/views/shop/PageArbitral.vue'),
      meta: { title: '仲裁审核', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'as_sale',
      component: () => import('@/views/shop/PageAsSale.vue'),
      meta: { title: 'AS售卖审核', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'notice',
      component: () => import('@/views/shop/PageShopNotice.vue'),
      meta: { title: 'AS售卖审核', noClose: false, showInMenuFlag: true }
    },
  ]
}
