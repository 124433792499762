import { SHOPID } from '@/constants'
import { service } from '@/utils/request'
import { getSession } from '@/utils/session'

export default {
  getShopMaster (payload) {
    return service.get('/shopStaff/getShopMaster', {
      params: payload
    })
  },
  authentication (payload) {
    return service.postForm('/shopBankCard/authentication', payload)
  },
  saveBankCard (payload) {
    return service.post('/shopBankCard/save', payload)
  },
  getBankCardPage (payload) {
    return service.get('/shopBankCard/list', {
      params: payload
    })
  },
  deleteBank (payload) {
    return service.postForm('/shopBankCard/delete', payload)
  },
  getShopAccouont (payload) {
    return service.get('/shopAccount/get', {
      params: payload
    })
  },
  getBalanceRecordPage (payload) {
    return service.get('/ShopCashApply/page', {
      params: payload
    })
  },
  shopCashApply (payload) {
    return service.post('/ShopCashApply/save', payload)
  },
  getShopAccountPage (payload) {
    return service.get('/shopAccount/page', {
      params: payload
    })
  },
  getRecommendData (payload) {
    return service.get('/staffDevelop/settleInfo', {
      params: payload
    })
  },
  getSupplyShopList (payload) {
    return service.get('staffDevelop/supplyShopList', {
      params: payload
    })
  },
  getSettlePage (payload) {
    return service.get('/staffDevelop/settlePage', { params: payload })
  },
  getSettleRecordPage (payload) {
    return service.get('/staffDevelop/pageSettleOrder', { params: payload })
  },
  getCashCouponFlowHistoryPage (payload) {
    return service.get('/shop/cash/coupon/account/bill/page', { params: {
      ...payload,
      shopId: getSession(SHOPID)
    } })
  },
  getCashCouponSummary () {
    return service.get('/shop/cash/coupon/account/get', { params: { shopId: getSession(SHOPID) } })
  },
  exportCashCouponFlowHistory (params) {
    return service.get('/shop/cash/coupon/account/bill/export', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  // 会员列表
  getMemberPage (params) {
    return service.get('/user/account/user/page', {
      params
    })
  },
  // 充值
  async chargeMoney (payload) {
    const { code, msg } = await service.post('/user/account/recharge', payload)
    if (code !== '00000') throw new Error(msg)
  },
  // 充卡
  async chargeVoucher (payload) {
    const { code, msg } = await service.post('/recharge/order/create', payload)
    if (code !== '00000') throw new Error(msg)
  },
  // 充值变动记录
  getChargeAccountHistoryPage (params) {
    return service.get('/user/account/page', {
      params
    })
  },
  // 现货仓单记录
  getXhcdHistoryPage (params) {
    return service.get('/user/account/xhcd/page', {
      params
    })
  },
  // 积分保记录
  getBaoHistoryPage (params) {
    return service.get('/user/account/bao/pageBao', {
      params
    })
  },
  // 积分保收益记录
  getBaoBillHistoryPage (params) {
    return service.get('/user/account/bao/pageBaoBill', {
      params
    })
  },
  // 自营商品收入报表分页
  getSelfGoodsIncomeReport (params) {
    return service.get('/settle/pageProductIncome', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
    })
  },
  // 自营商品收入报表导出
  exportSelfGoodsIncomeReport (params) {
    return service.get('/settle/exportProductIncome', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  // 获取店铺供应商
  getSupplyShopOfMine (shopStaffId) {
    return service.get(`/shopFollow/listPartner/${getSession(SHOPID)}/0`, {
      params: {
        shopStaffId,
      }
    })
  },
  // 获取店铺分销商
  getRetailerShopOfMine (shopStaffId) {
    return service.get(`/shopFollow/listPartner/${getSession(SHOPID)}/1`, {
      params: {
        shopStaffId,
      }
    })
  },
  // 分销商品收入报表分页
  getThirdGoodsIncomeReport (params) {
    return service.get('/settle/pageTradeIncome', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
    })
  },
  // 分销商品收入报表导出
  exportThirdGoodsIncomeReport (params) {
    return service.get('/settle/exportTradeIncome', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  // 分销商品排名 分页
  getThirdGoodsIncomeRank (params) {
    return service.get('/settle/pageSelfTradeRank', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
    })
  },
  // 分销商品排名 导出
  exportThirdGoodsIncomeRank (params) {
    return service.get('/settle/exportSelfTradeRank', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  // 自营商品排名 分页
  getSelfGoodsIncomeRank (params) {
    return service.get('/settle/pageProductRank', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
    })
  },
  // 自营商品排名 导出
  exportSelfGoodsIncomeRank (params) {
    return service.get('/settle/exportProductRank', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  // 结算账户总览
  getSettleInfo () {
    return service.get('/settle/settleInfo', {
      params: {
        shopId: getSession(SHOPID),
      }
    })
  },
  // 待结算货款，佣金
  getWaitSettleInfo () {
    return service.get('/settle/waitSettleAmount', {
      params: {
        shopId: getSession(SHOPID),
      }
    })
  },
  // 待结算分页
  getWaitSettlePage (params) {
    return service.get('/settle/pageWaitSettleOrder', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
    })
  },
  // 待结算导出
  exportWaitSettle (params) {
    return service.get('/settle/exportWaitSettleOrder', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  // 可结算货款/佣金/奖励金
  getCanSettleInfo () {
    return service.get('/settle/canSettleAmount', {
      params: {
        shopId: getSession(SHOPID),
      }
    })
  },
  // 可结算分页
  getCanSettlePage (params) {
    return service.get('/settle/pageCanSettleDetail', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
    })
  },
  // 可结算导出
  exportCanSettle (params) {
    return service.get('/settle/exportCanSettleDetail', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  // 已结算货款/佣金/奖励金
  getAlreadySettleInfo () {
    return service.get('/settle/finishSettleAmount', {
      params: {
        shopId: getSession(SHOPID),
      }
    })
  },
  // 已结算分页
  getAlreadySettlePage (params) {
    return service.get('/settle/pageFinishSettleDetail', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
    })
  },
  // 已结算导出
  exportAlreadySettle (params) {
    return service.get('/settle/exportFinishSettleDetail', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  // 平台服务费开票请求分页
  getInvoiceServiceRequestPage (params) {
    return service.get('/invoiceRecord/page', {
      params: {
        ...params,
        shopId: getSession(SHOPID),
        type: 0,
      }
    })
  },
  // 平台服务费开票订单
  getInvoiceServiceOrder (params) {
    return service.get('/invoiceRecord/listExtendOrder', {
      params: {
        shopId: getSession(SHOPID),
        ...params
      }
    })
  },
  getListTradeCommissionOrder (params) {
    return service.get('/invoiceRecord/listTradeCommissionOrder', {
      params: {
        shopId: getSession(SHOPID),
        ...params
      }
    })
  },
  // 历史开票信息
  getInvoiceHistory () {
    return service.get('/invoiceInfo', {
      params: {
        shopId: getSession(SHOPID),
      }
    })
  },

  // 申请平台服务费发票/应开具平台发票
  async requestServiceInvoice (payload) {
    const { code, msg } = await service.post('/invoiceRecord/save', {
      ...payload,
      shopId: getSession(SHOPID),
      type: 0,
    })
    if (code !== '00000') throw new Error(msg)
  },
  // 开具佣金发票给平台
  async createCommissionInvoice (payload) {
    const { code, msg } = await service.post('/invoiceRecord/save', {
      ...payload,
      shopId: getSession(SHOPID),
      type: 2,
    })
    if (code !== '00000') throw new Error(msg)
  },
  // 应开佣金发票分页
  getCommissionInvoicePage (params) {
    return service.get('/invoiceRecord/page', {
      params: {
        ...params,
        shopId: getSession(SHOPID),
        type: 2,
      }
    })
  },
  // 应开佣金发票订单
  getCommissionInvoiceOrder (params) {
    return service.get('/invoiceRecord/listTradeCommissionOrder', {
      params: {
        shopId: getSession(SHOPID),
        ...params
      }
    })
  },
  // 消费者发票分页
  getCustomerInvoicePage (params) {
    return service.get('/invoiceRecord/page', {
      params: {
        ...params,
        shopId: getSession(SHOPID),
        type: 1,
      }
    })
  },
  // 上传发票
  uploadInvoice (payload) {
    return service.postForm('/invoiceRecord/uploadInvoice', payload)
  },
  // 用户权益券
  getUserQyqPage (params) {
    return service.get('/agent/team/qyq/page', {
      params
    })
  },
  // 购物积分会员列表
  getBalancePage (params) {
    return service.get('/user/balance/user/page', {
      params
    })
  },
  // 购物积分充值
  async chargeBalance (payload) {
    const { code, msg } = await service.post('/user/balance/recharge', payload)
    if (code !== '00000') throw new Error(msg)
  },
  // 权益券充值
  async chargeQyq (payload) {
    const { code, msg } = await service.post('/agent/team/rechargeQyq', payload)
    if (code !== '00000') throw new Error(msg)
  },
  // 购物积分变动记录
  getChargeBalanceHistoryPage (params) {
    return service.get('/user/balance/page', {
      params
    })
  },
}
