/* 各模块路由 */
import activity from './modules/activity'
import capital from './modules/capital'
import goods from './modules/goods'
import helper from './modules/helper'
import index from './modules/index'
import logistics from './modules/logistics'
import order from './modules/order'
import partner from './modules/partner'
import shop from './modules/shop'
import system from './modules/system'
import content from './modules/content'
import purchase from './modules/purchase'
import customer from './modules/customer'
import marketing from './modules/marketing'
import agent from './modules/agent'
import bean from './modules/bean'
import as from './modules/as'

const asyncRoutes = [
  index,
  shop,
  helper,
  goods,
  order,
  capital,
  partner,
  system,
  logistics,
  activity,
  content,
  purchase,
  customer,
  marketing,
  bean,
  as,
  agent
]

handleMeta(asyncRoutes)

function handleMeta (routes) {
  for (let route of routes) {
    if (!route.meta) {
      route.meta = {}
    }
    if (route.children && route.children.length) {
      handleMeta(route.children)
    }
  }
}

export default asyncRoutes
