import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/as',
  component: AppLayout,
  children: [
    {
      path: 'charge_as',
      component: () => import('@/views/as/PageChargeAs.vue'),
      meta: { title: 'as管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/as/history',
      component: () => import('@/views/as/PageChargeAsHistory.vue'),
      meta: { title: 'as变动记录', noClose: false, showInMenuFlag: true }
    },

    {
      path: 'charge_tp',
      component: () => import('@/views/as/PageChargeTp.vue'),
      meta: { title: 'tp管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/tp/history',
      component: () => import('@/views/as/PageChargeTpHistory.vue'),
      meta: { title: 'tp变动记录', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge_zp',
      component: () => import('@/views/as/PageChargeZp.vue'),
      meta: { title: 'zp管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/zp/history',
      component: () => import('@/views/as/PageChargeZpHistory.vue'),
      meta: { title: 'zp变动记录', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge_ep',
      component: () => import('@/views/as/PageChargeEp.vue'),
      meta: { title: 'ep管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/ep/history',
      component: () => import('@/views/as/PageChargeEpHistory.vue'),
      meta: { title: 'ep变动记录', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge_rp',
      component: () => import('@/views/as/PageChargeRp.vue'),
      meta: { title: 'rp管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/rp/history',
      component: () => import('@/views/as/PageChargeRpHistory.vue'),
      meta: { title: 'rp变动记录', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge_sp',
      component: () => import('@/views/as/PageChargeSp.vue'),
      meta: { title: 'sp管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/sp/history',
      component: () => import('@/views/as/PageChargeSpHistory.vue'),
      meta: { title: 'sp变动记录', noClose: false, showInMenuFlag: true }
    },
  ]
}
